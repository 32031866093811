import Vue from 'vue'
import router from '@/router'
// eslint-disable-next-line object-curly-newline
import { reactive, getCurrentInstance, watch, toRefs } from '@vue/composition-api'
import { VueMaskFilter } from 'v-mask'
import { format, parse, parseISO } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

const getRandomFromArray = array => array[Math.floor(Math.random() * array.length)]

// ? Light and Dark variant is not included
// prettier-ignore
export const getRandomBsVariant = () => getRandomFromArray(['primary', 'secondary', 'success', 'warning', 'danger', 'info'])

export const isDynamicRouteActive = route => {
  const { route: resolvedRoute } = router.resolve(route)
  return resolvedRoute.path === router.currentRoute.path
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy
  const state = reactive({
    route: vm.$route,
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    },
  )

  return { ...toRefs(state), router: vm.$router }
}

/**
 * This is just enhancement over Object.extend [Gives deep extend]
 * @param {target} a Object which contains values to be overridden
 * @param {source} b Object which contains values to override
 */
// export const objectExtend = (a, b) => {
//   // Don't touch 'null' or 'undefined' objects.
//   if (a == null || b == null) {
//     return a
//   }

//   Object.keys(b).forEach(key => {
//     if (Object.prototype.toString.call(b[key]) === '[object Object]') {
//       if (Object.prototype.toString.call(a[key]) !== '[object Object]') {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = b[key]
//       } else {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = objectExtend(a[key], b[key])
//       }
//     } else {
//       // eslint-disable-next-line no-param-reassign
//       a[key] = b[key]
//     }
//   })

//   return a
// }

export const maskHiddenCpf = cpf => cpf.replace(/(\d{6})(\d{3})(\d{2})/, '***.***.$2-$3')
export const maskHiddenCpf2 = cpf => cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '***.$2.$3-**')
export const maskCnpj = cnpj => cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')

export const formatNumber = val => new Intl.NumberFormat('pt-BR').format(val)
export const formatCurrency = val => val.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
export const formatCurrencyNonDecimal = val => parseInt(val, 10).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 0 })
export const formatCurrencyInt = val => (val ? formatCurrency(val / 100) : formatCurrency(val / 100))
export const formatPercent = val => (val ? `${val}%` : '0%')
export const formatCnpj = cnpj => VueMaskFilter(cnpj, '##.###.###/####-##')
export const formatCpf = (cpf, anonymize = false) => {
  if (anonymize && cpf) return cpf.replace(/(\d{6})(\d{3})(\d{2})/, '***.***.$2-$3')
  return VueMaskFilter(cpf, '###.###.###-##')
}
export const formatCep = cep => VueMaskFilter(cep, '#####-###')
export const formatPhone = phone => VueMaskFilter(phone, '(##) #####-####')
export const formatDate = date => format(date, 'dd/MM/yyyy')
export const formatDateTime = date => {
  if (!date) return ''
  if (typeof date === 'string') {
    return format(parse(date, 'yyyy-MM-dd HH:mm:ss', new Date(), { locale: ptBR }), 'dd/MM/yyyy HH:mm', { locale: ptBR })
  }
  return format(date, 'dd/MM/yy HH:mm')
}

export const formatDateTimeDDMMYY = date => {
  if (!date) return ''
  if (typeof date === 'string') {
    return format(parse(date, 'yyyy-MM-dd', new Date(), { locale: ptBR }), 'dd/MM/yy', { locale: ptBR })
  }
  return format(date, 'dd/MM/yy', { locale: ptBR })
}

export const formatDateTimeDDMMYYYY = date => {
  if (!date) return ''
  if (typeof date === 'string') {
    return format(parse(date, 'yyyy-MM-dd', new Date(), { locale: ptBR }), 'dd/MM/yyyy', { locale: ptBR })
  }
  return format(date, 'dd/MM/yyyy', { locale: ptBR })
}

export const formatDateTimeMMMMYYYY = date => {
  if (!date) return ''
  if (typeof date === 'string') {
    return format(parse(date, 'yyyy-MM', new Date(), { locale: ptBR }), 'MMMM/yyyy', { locale: ptBR })
  }
  return format(date, 'MMMM/yyyy', { locale: ptBR })
}

export const formatDateTimeDDMMYYHHMM = date => {
  if (!date) return ''
  if (typeof date === 'string') {
    return format(parse(date, 'yyyy-MM-dd HH:mm:ss', new Date(), { locale: ptBR }), 'dd/MM/yy HH:mm', { locale: ptBR })
  }
  return format(date, 'dd/MM/yy HH:mm', { locale: ptBR })
}

export const formatDateTimeDDMMYYHHMMSS = date => {
  if (!date) return ''
  if (typeof date === 'string') {
    return format(parse(date, 'yyyy-MM-dd HH:mm:ss', new Date(), { locale: ptBR }), 'dd/MM/yy HH:mm', { locale: ptBR })
  }
  return format(date, 'dd/MM/yy HH:mm:ss', { locale: ptBR })
}

export const formatISODateTimeDDMMYYHHMMSS = date => {
  if (!date) return ''
  if (typeof date === 'string') {
    return format(parseISO(date), 'dd/MM/yy HH:mm', { locale: ptBR })
  }
  return format(date, 'dd/MM/yy HH:mm:ss', { locale: ptBR })
}

export const toastValidationErrors = error => {
  if (error.response && error.response.status === 422) {
    const errors = (error.response && error.response.status === 422 && error.response.data.errors) || {}
    const message = Object.keys(errors).map(key => errors[key][0]).join(',')
    Vue.$toast({
      component: ToastificationContent,
      position: 'top-right',
      props: {
        title: 'Erro',
        icon: 'XOctagonIcon',
        variant: 'danger',
        text: message,
      },
    })
  }
}

export const pad = (str, size) => (`${str}` || '').padStart(size, '0')

export const resolveAccountType = type => {
  const map = {
    checking_account: 'CC',
    savings_account: 'CP',
  }
  return map[type]
}

export const downloadBlob = (filename, response) => {
  const blob = new Blob([response.data], { type: response.headers['content-type'] })
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = filename
  link.click()
  URL.revokeObjectURL(link.href)
}

export const downloadFiles = (filename, text) => {
  const element = document.createElement('a')
  element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`)
  element.setAttribute('download', filename)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

export const truncateString = (str, num) => {
  if (!str) return ''
  if (str.length <= num) {
    return str
  }
  return `${str.slice(0, num)}...`
}

export const roundTripArray = (arr, index) => arr[((index % arr.length) + arr.length) % arr.length]
export const roundNumber = n => Math.round(n * 100) / 100
